.modal-video {
  transition: all 300ms ease-in-out;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  opacity: 0;
  article {
    position: absolute;
    left: 50%;
    top: 15%;
    width: calc(100% - 20px);
    max-width: 1000px;
    transform: translate(-50%, -50px);
    @media only screen and (min-width: 992px) {
      width: calc(100% - 200px);
      top: 50%;
      transform: translate(-50%, -50%);
    }
    video {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }
    aside {
      color: $white;
      position: absolute;
      right: -20px;
      top: -63px;
      font: 300 37px/1em "Gotham";
      cursor: pointer;
      user-select: none;
      padding: 20px;
    }
  }
}

.modal-video-yt {
  aspect-ratio: 16/9;
  iframe {
    height: 100%;
    width: 100%;
  }
}
