fieldset.checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 18px;
    height: 18px;
    z-index: 10;
    cursor: pointer;

    & + label {
      position: absolute;
      cursor: pointer;
      padding: 0;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      cursor: pointer;
      border: 1px solid transparent;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 8px;
      height: 8px;
      border: 1px solid transparent;
    }
  }

  p {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    width: calc(100% - 30px);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &[data-theme="dark"] {
    input[type="checkbox"] {
      & + label:before {
        background: $black;
      }
      &:checked + label:after {
        background: $yellow;
      }
    }
  }

  &[data-theme="light"] {
    input[type="checkbox"] {
      & + label:before {
        background: $white;
        border: 1px solid $lightGrey;
      }
      &:checked + label:after {
        background: $yellow;
      }
    }
  }
}


fieldset.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 18px;
    height: 18px;
    z-index: 10;
    cursor: pointer;

    & + label {
      position: absolute;
      cursor: pointer;
      padding: 0;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid transparent;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 8px;
      height: 8px;
      border: 1px solid transparent;
      border-radius: 50%;
    }
  }

  p {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    width: calc(100% - 30px);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &[data-theme="dark"] {
    input[type="radio"] {
      & + label:before {
        background: $black;
      }
      &:checked + label:after {
        background: $yellow;
      }
    }
  }

  &[data-theme="light"] {
    input[type="radio"] {
      & + label:before {
        background: $white;
        border: 1px solid $lightGrey;
      }
      &:checked + label:after {
        background: $yellow;
      }
    }
  }
}



