.btn {
  height: 40px;
  cursor: pointer;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  a {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: inherit;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 38px;
    font-weight: 500;
    text-align: center;
    float: left;
    padding: 0 25px;

    /*@-moz-document url-prefix() {
      line-height: 48px;
    }*/

    i {
      vertical-align: middle;
    }

  }

  &-outline {
    border-width: 1px;
    border-style:solid;
    line-height: 2;

    &--white {
      border-color: $white;
      color: $white;
    }

    &--black {
      border-color: $black;
      color: $black;
    }

    &--yellow {
      border-color: $yellow;
      color: $black;
    }
  }

  &-filled {
    border: 1px solid transparent;
    line-height: 1.8;
    position: relative;
    overflow: hidden;

    &--white {
      background: $white;
      color: #333;

      &:hover {
        color: $black;
      }
    }

    &--yellow {
      background: $yellow;
      color: #333;
      &:hover {
        color: $black;
      }
    }

    &--red {
      background: $red;
      color: $white;
      &:hover {
        color: $white;
      }
    }

    &--black {
      background: $black;
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }


  &--small {
    padding: 5px 10px;
    line-height: initial;
    height: auto;
    a {
      font-size: 12px;
      font-weight: 400;
      vertical-align: 1px;
    }
  }
}