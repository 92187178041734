//variable block
$productSwiperScaleFactor:100px;

//rule block
html{
    body{
        .product-gallery{
            padding-left:6.11%;
            padding-right:6.11%;
            @media only screen and (max-width: 768px) {
                padding-left:0%;
                padding-right:0%;
            }
            .slider-wrapper{
                margin-top: 74px;
            }
            img{
                height: auto;
                width: 100%;
            }
            #product-gallery-swiper{
                // overflow: visible;
                padding-bottom: 69px;
                margin-top: -$productSwiperScaleFactor;
                @media only screen and (max-width: 768px) {
                    padding-bottom: 0px;
                }
                .swiper-pagination{
                    bottom: 15px;
                    .swiper-pagination-bullet{
                        width: 4px;
                        height: 4px;
                        margin: 9px 12px;
                        opacity: 0.3;
                        background-color: #ffffff;
                        transition: 0.3s opacity ease-in-out,0.3s height ease-in-out,0.3s width ease-in-out ;
                        &:after{
                            content:'';
                            width: 22px;
                            height: 22px;
                            position: absolute;
                            transform: translate(-50%, -36%);
                            border-radius: 50px;
                            border: 1px solid white;
                            opacity: 0;
                            transition: 0.3s opacity ease-in-out;
                        }
                        &-active{
                            width: 6px;
                            height: 6px;
                            background-color: #ffffff;
                            opacity: 1;
                            &:after{
                                opacity: 1;
                            }
                        }
                    }
                }
                .swiper-wrapper{
                    .swiper-slide{
                        padding-top: $productSwiperScaleFactor;
                        z-index: 10;
                        .inner-wrapper{
                            padding: 0px;
                        }
                    }
                }
            }
        }
        .long-arrow-next, .long-arrow-prev {
            height: 60px;
            width: 60px;
            border-radius: 60px;
            background-color: white;
            svg {
                height: 20px;
                path{
                    fill: #1f1f1f;
                }
            }
            &:after{
                content: none;
            }
        }

        .category-tag{
            background-color: #1f1f1f;
            padding: 6px 12px;
            width: fit-content;
            span {
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1px;
                text-align: center;
                color: #1f1f1f;

                text-transform: uppercase;
            }
        }

        .section-title{
            font-size: 2.75rem;
            font-weight: normal;
            font-stretch: normal;
              // font-style: italic;  //test comment
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            color: #1f1f1f;
        }
        
        .swiper-container {
					position: relative;
					width: 100%;
					height: 100%;
        }

        .swiper-slide {
        @media screen and (max-width: 768px) {
            height: auto;
        }
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        .inner-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 10px;
            .category{
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 1.5px;
                text-align: left;
                color: #1f1f1f;

                margin-bottom: 10px;
            }
            .title{
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.8;
                letter-spacing: 1.5px;
                text-align: left;
                color: #1f1f1f;

                margin-bottom: 20px;
            }
            .image-wrapper{
                position: relative;
                .learn-more{
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 2px;
                    text-align: center;

                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    background-color: #252525;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                    transition: all 0.5s ease-in-out;
                    @media screen and (max-width: 768px) {
                        bottom: 0px;
                        right: 0px;
                    }
                    @media screen and (max-width: 320px) {
                        bottom: 0px;
                        right: 0px;
                    }
                    .inner-text{
                        color:white;
                        text-decoration: none;
                        width: 100%;
                        transition: opacity 0.2s ease-in-out;
                    }
                    .svg-wrapper{
                        background-color: white;
                        height: 100%;
                        width:45px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        svg{ 
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform:translate(-50% , -50%);
                            max-width: 20px;
                            transition: fill 0.5s ease-in-out;
                            path{
                                fill: white;
                            }
                        }
                    }
                }
            }
        }
        }
    }
}

@media only screen and (max-width: 768px) {
    html body{
        .section-title,
        .insta-feed .section-title {
            font-size: 2rem;
        }
        #product-gallery #product-gallery-swiper{
            .swiper-slide{
                .image-wrapper{
                    height: fit-content;
                    transition: height 0.3s ease-in-out;
                    overflow: hidden;
                }

                .category, .title{
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }

                &.swiper-slide-active{
                    .category, .title{
                        opacity: 1;
                    }
                    // .image-wrapper{
                    //     height: 236px;
                    // }
                }
                
                .learn-more{
                    height: 34px;
                    width: 34px;
                    padding: 0px;
                    .inner-text{
                        display: none;
                    }
                    .svg-wrapper {
                        width: 100%;
                    }
                }
            }
        }

    } 
}


html body .insta-feed-body .swiper-slide .inner-wrapper .image-wrapper {
    width: 100%;
    height: 301px;
    position: relative;
}