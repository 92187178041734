// HEIGHT/WIDTH/FLOAT/OVERFLOW

.fw {
  width:100%;
}
.fh {
  height:100%;
}
.fh-vh {
  height:100vh;
}
.fw-vw {
  width:100vw;
}
.fl-l {
  float: left !important;
}
.fl-r {
  float: right !important;
}
.dis-ib {
  display: inline-block;
}
.ov-h {
  overflow: hidden;
}

// MARGINS

.m-0 {
  margin: 0px !important;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-30 {
  margin: 30px;
}
.m-60 {
  margin: 60px;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-0{
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-60 {
  margin-left: 60px;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-60 {
  margin-right: 60px;
}
.mt-mb-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.mt-mb-5 {
  margin:5px 0 5px 0;
}
.mt-mb-10 {
  margin:10px 0 10px 0;
}
.mt-mb-15 {
  margin:15px 0 15px 0;
}
.mt-mb-30 {
  margin:30px 0 30px 0;
}
.mt-mb-60 {
  margin:60px 0 60px 0;
}
.ml-mr-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.ml-mr-5 {
  margin: 0 5px 0 5px;
}
.ml-mr-10 {
  margin: 0 10px 0 10px;
}
.ml-mr-15 {
  margin: 0 15px 0 15px;
}
.ml-mr-30 {
  margin: 0 30px 0 30px;
}
.ml-mr-60 {
  margin: 0 60px 0 60px;
}

// NEGATIVE MARGINS


.-mt-5 {
  margin-top: -5px;
}
.-mt-10 {
  margin-top: -10px;
}
.-mt-15 {
  margin-top: -15px;
}
.-mt-30 {
  margin-top: -30px;
}
.-mt-60 {
  margin-top: -60px;
}
.-mb-5 {
  margin-bottom: -5px;
}
.-mb-10 {
  margin-bottom: -10px;
}
.-mb-15 {
  margin-bottom: -15px;
}
.-mb-30 {
  margin-bottom: -30px;
}
.-mb-60 {
  margin-bottom: -60px;
}
.-ml-5 {
  margin-left: -5px;
}
.-ml-10 {
  margin-left: -10px;
}
.-ml-15 {
  margin-left: -15px;
}
.-ml-30 {
  margin-left: -30px;
}
.-ml-60 {
  margin-left: -60px;
}
.-mr-5 {
  margin-right: -5px;
}
.-mr-10 {
  margin-right: -10px;
}
.-mr-15 {
  margin-right: -15px;
}
.-mr-30 {
  margin-right: -30px;
}
.-mr-60 {
  margin-right: -60px;
}


// PADDING


.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.p-60 {
  padding: 60px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-60 {
  padding-left: 60px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-60 {
  padding-right: 60px;
}
.pt-pb-5 {
  padding-top:5px;
  padding-bottom:5px;
}
.pt-pb-10 {
  padding-top:10px;
  padding-bottom:10px;
}
.pt-pb-15 {
  padding-top:15px;
  padding-bottom:15px;
}
.pt-pb-30 {
  padding-top:30px;
  padding-bottom:30px;
}
.pt-pb-60 {
  padding-top:60px;
  padding-bottom:60px;
}
.pl-pr-5 {
  padding-left:5px;
  padding-right:5px;
}
.pl-pr-10 {
  padding-left:10px;
  padding-right:10px;
}
.pl-pr-15 {
  padding-left:15px;
  padding-right:15px;
}
.pl-pr-30 {
  padding-left:30px;
  padding-right:30px;
}
.pl-pr-60 {
  padding-left:60px;
  padding-right:60px;
}
.p-0 {
  padding:0 !important;
}
.pl-0 {
  padding-left:0 !important;
}
.pr-0 {
  padding-left:0 !important;
}
.pt-0 {
  padding-top:0 !important;
}
.pb-0 {
  padding-bottom:0 !important;
}
.pt-pb-0 {
  padding-top:0 !important;
  padding-bottom:0 !important;
}
.pl-pr-0 {
  padding-left:0 !important;
  padding-right:0 !important;
}


// BORDERS


.b-1 {
  border: 1px solid; }

.b-2 {
  border: 2px solid; }

.b-3 {
  border: 3px solid; }

.b-4 {
  border: 4px solid; }

.bt-1 {
  border-top: 1px solid; }

.bt-2 {
  border-top: 2px solid; }

.bt-3 {
  border-top: 3px solid; }

.bt-4 {
  border-top: 4px solid; }

.bb-1 {
  border-bottom: 1px solid; }

.bb-2 {
  border-bottom: 2px solid; }

.bb-3 {
  border-bottom: 3px solid; }

.bb-4 {
  border-bottom: 4px solid; }

.bl-1 {
  border-left: 1px solid; }

.bl-2 {
  border-left: 2px solid; }

.bl-3 {
  border-left: 3px solid; }

.bl-4 {
  border-left: 4px solid; }

.br-1 {
  border-right: 1px solid; }

.br-2 {
  border-right: 2px solid; }

.br-3 {
  border-right: 3px solid; }

.br-4 {
  border-right: 4px solid; }


// BORDER RADIUS

.brs-2 {
  border-radius: 2px;
}
.brs-3 {
  border-radius: 3px;
}
.brs-4 {
  border-radius: 4px;
}
.brs-5 {
  border-radius: 5px;
}
.brs-6 {
  border-radius: 6px;
}
.brs-8 {
  border-radius: 8px;
}
.brs-10 {
  border-radius: 10px;
}
.brs-15 {
  border-radius: 15px;
}
.brs-20 {
  border-radius: 20px;
}
.brs-25 {
  border-radius: 25px;
}
.brs-30 {
  border-radius: 30px;
}
.brs-50 {
  border-radius: 50%;
}
.brs-100 {
  border-radius: 100%;
}



// COL

.col-1 {
  width: 10%;
}

.col-2 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-33 {
  width: 33.3%;
}

.col-3 {
  width: 30%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-6 {
  width: 60%;
}

.col-7 {
  width: 70%;
}

.col-8 {
  width: 80%;
}

.col-9 {
  width: 90%;
}
.tt-u {
  text-transform: uppercase;
}
.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.col {
  &--2 {
    width: 20%;
  }
  &--5 {
    width: 50%;
  }
  &--6 {
    width: 60%;
  }
}