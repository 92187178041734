@import "config/breakpoints";

header#topbar{
  position: fixed;
  z-index: 101;
  height: auto;
  transition: all 0.2s ease-in-out;
  top: 0px;
	width: 100%;

  &.topbar--black-theme{
    svg path {fill: black !important;}
  }
  &.hidden{
    top: -60px;
  }
  &.side__menu-open #navigationMenu.navigationMenu  {
    .navigationMenu__pdesignlogo svg path {
      fill: white;
    }
    .menu-content {
      left: 0px;
    }
  }

#navigationMenu.navigationMenu{
  height: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 20px auto 20px 36px;
  width: fit-content;

  @media only screen and (max-width: 768px) {
    margin: 20px auto 20px auto;
  }
    #menu-opener{
      cursor: pointer;
      height: 100%;
      svg{
        height: 20px;
        path{
          fill: white;
          transition: 0.5s fill ease-in-out;
        }
      }
    }
    .navigationMenu__pdesignlogo{
      margin-left: 36px;
      height: 100%;
      z-index: 1000;
      svg{
        height: 100%;
        width: auto;
        path{
          fill: white;
          transition: 0.3s fill ease-in-out;
        }
      }
    }
    #side-menu-content {
      z-index:100;

      .inner-wrapper {
        padding: 80px 40px 40px 60px;
        height: 100%;
        position: relative;

        .menu-close-icon {
          position: absolute;
          left: 36px;
          top: 15px;
          .p-design-logo{
            display: none;
          }
        }
        .menu-intro{
          font-size: 2.75rem;
          font-weight: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #1f1f1f;
        }
				.menu-list {
					height: 80%;
					overflow-y: scroll;
					-ms-overflow-style: none;  /* IE and Edge */
  				scrollbar-width: none;  /* Firefox */
					&::-webkit-scrollbar {
						display: none;
					}
					.menu-link {
						font-size: 1.375rem;
						font-weight: 100;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.27;
						letter-spacing: normal;
						text-align: left;

						margin-top: 28px;
						a {
							color: white;
							text-decoration: none;
							padding: 10px 0px;
							cursor: pointer;
						}
					}
				}
        
        .menu-bottom{
          position: absolute;
          bottom: 40px;
          @media screen and (max-width:320px) {
            bottom: 49px;
          }
          @media screen and (max-width:414px) {
            bottom: 60px;
          }
          width: calc(100% - 130px);
          border-image-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .language-selector {
            display: flex;
            flex-direction: row;
          }
          .gotoPirelli {
            a {
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.38;
              letter-spacing: 2px;
              text-align: left;
              color: white;
              text-decoration: none;
              text-transform: uppercase;
            }
          }
          ul.language-selector{
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.38;
            letter-spacing: 2px;
            text-align: right;
            a{
              color: #1f1f1f;
            }
            .change-lang.active{
              font-weight: 500;
            }
          }
          .change-lang-divider{
            margin: 0px 10px;
            line-height: 37px;
          }
        }
      }

    }
    #black-layer-overlay{
      z-index: 90;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      background: transparent;
      width: 0vw;
      transition: background-color 0.5s ease-in-out;
      &.visible{
        width: 100vw;
        background-color: #00000066;
      }
    }

    .menu-content {
      position: fixed;
      left: -45vw;
      top: 0;
      height: 100vh;
      background-color: #1f1f1f;
      width: 45vw;
      transition: all 0.5s ease-in-out;
    }


    .menu-toggler{
      cursor: pointer;
      svg{
        height: 30px;
        .closeicon{
          height: 60px;
          padding-bottom: 15px;
        }
        path{
          fill: white;
        }
      }
    }

    .borderOnHover{
      position: relative;
      &:after{
        width: 0%;
        position: absolute;
        bottom: -4px;
        left: 0px;
        content: '';
        border-bottom: 1px solid #1f1f1f80;
        transition: width 0.3s ease-in-out;
      }
      &:hover:after{
        width: 100%;
      }
    }


    .italicOnHover{
      position: relative;
      transition: width 0.3s ease-in-out;
      font-style: normal;
      &:hover{
          font-style: italic;  //test comment
      }
    }
    
  }
}

/* "from the archive" label */
.additionalLabel {
  display: inline-block;
  background: #fff;
  color: #1f1f1f;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  padding: 4px;
  margin-left: 16px;
  text-transform: uppercase;
  font-style: normal !important;
  @media (max-width: $mobileDown) {
    margin-left: 12px;
    font-size: 10px;
  }
}

@media only screen and (min-width: 1079px) and (max-width: 1439px)  {
  header#topbar #navigationMenu.navigationMenu #side-menu-content .inner-wrapper{
    padding: 40px 40px 40px 90px;
    .menu-intro {
      font-size: 2rem;
    }
    .menu-list .menu-link{
      font-size: 1.2rem;
      margin-top: 20px;
      margin-top: 21px;
      &:first-of-type{
        margin-top: 40px;
      }
    }
  } 
}

@media only screen and (max-width: 768px) {
  header#topbar {
    width: 100%;
    &.side__menu-open #navigationMenu.navigationMenu #side-menu-content{
      right: 0vw;
    }
  }
  header#topbar #navigationMenu.navigationMenu{
    flex-direction: row-reverse;

    width: calc(100% - 40px);
    .navigationMenu__pdesignlogo{
      margin-left: 0px;
      max-width: 150px;
      margin-right: auto;
      svg {
        height: auto;
        width: 100%;
    }
    }
    #side-menu-content {
      width: 100vw;
      right: -100vw;
      left: unset;
      z-index: 1000;
      &.open{
        right: 0px;
      }
      .inner-wrapper{
        padding: 20px 30px 20px 30px;
        .menu-close-icon {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          position: absolute;
          width: calc(100% - 60px);
          right: 30px;
          left: unset;
          top: 20px;
          .p-design-logo{
            display: block;
            max-width: 150px;
            svg{
              max-width: 150px;
            }
          }
        }
        .menu-intro {
          font-size: 1.75rem;
          margin-top: 60px;
        }
        .menu-list{
					margin-top: 25px;
					height: 65vh;
          .menu-link{
            font-size: 1rem;
            margin-top: 25px;
          }
        } 
        .menu-bottom {
          width: calc(100% - 60px);
        }
      } 
    }
  } 
}


@media only screen and (max-width: 414px) {
  header#topbar{
    #navigationMenu.navigationMenu{
      #side-menu-content .inner-wrapper{
        .menu-bottom {
          bottom: 90px;
        }
      } 
    }
  } 
}

@media only screen and (max-width: 320px) {
  header#topbar{
    #navigationMenu.navigationMenu{
      #side-menu-content .inner-wrapper{
        .menu-bottom {
          position: absolute;
          bottom: 49px;
          display: grid;
        }
        .menu-intro {
          font-size: 1.5rem;
          margin-top: 60px;
        }
      } 
    }
  } 
}

