.img-responsive {
	width: 100%;
	height: auto;
	overflow: hidden;
}

html body .swiper-slide {
	background: transparent;
}

.img-responsive {
	width: 100%;
	height: auto;
}

.fill {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

@media screen and (max-width: 1200px) {
	.img-responsive {
		// width: 100%;
		// height: 100%;
	}
}

.fontWeight-medium {
	font-weight: normal;
}

.scaleOnHover {
	@media only screen and (min-width: 768px) {
		.image-wrapper {
			overflow: hidden;
			height: fit-content;
			margin: -10px -10px 0px -10px;
		}
		img {
			transition: transform 0.5s ease-out;
		}
		&:hover img {
			transform: scale(1.1);
		}
	}
}

.scaleOnActive {
	transition: transform 0.5s ease-in-out;
	transform-origin: bottom center;
	&.swiper-slide-active {
		transform: scale(1.2);
		margin-top: 19px;
		.learn-more {
			transform: scale(0.89);
			bottom: -2% !important;
			right: -2% !important;
		}
		@media screen and (max-width: 768px) {
			.learn-more {
				right: -2% !important;
				bottom: 30px !important;
			}
		}
		@media screen and (max-width: 414px) {
			.learn-more {
				right: -2% !important;
				bottom: -2px !important;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.scaleOnActive.swiper-slide-prev {
		margin-left: -17px;
	}
	.scaleOnActive.swiper-slide-active {
		transform: scale(1.2);
		margin-top: 10px;
		margin-right: 41px !important;
		margin-left: 21px !important;
	}
}
@media screen and (max-width: 320px) {
	.scaleOnActive.swiper-slide-prev {
		margin-left: -17px;
	}
	// .scaleOnActive.swiper-slide-next{ margin-left: 17px !important;  }
}

.bold {
	font-weight: bold;
}
.btn-buy-now a {
	width: fit-content !important;
}
.width-fitContent a {
	width: fit-content !important;
}

.fontWeight-bold {
	font-weight: bold;
	display: contents;
}

.fontWeightNormal {
	font-weight: normal;
}

.learnmore-opened {
	z-index: 2000 !important;
}

.effect-reverse {
	filter: invert(100%);
}

.fontWeight100 {
	font-weight: 100 !important;
}

.whitespace-nowrap {
	white-space: nowrap;
}

.btn-go-to-website {
	border-radius: 4px;
	border-radius: 120px;
	padding: 10px 40px;
	text-transform: uppercase;
	height: fit-content;
	a {
		font-size: 1rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: 2px;
		text-align: center;
		color: #000000;
		@media screen and (max-width: 413px) {
			font-size: 0.75rem;
			padding: 0px;
		}
		:first-of-type {
			display: none;
		}
	}
}
.unit-measurement {
	font-size: 20px;
	text-transform: uppercase;
}

.overlayText {
	width: calc(100% + 110px);
}
@media screen and (max-width: 768px) {
	.overlayText {
		width: 100% !important;
	}
}

.blueButton {
	background-color: #0737df;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 2px;
	text-align: center;
	color: #ffffff;
	padding: 20px 45px;
	border-radius: 40px;
	width: fit-content;
	margin: 13px auto auto auto;
	@media screen and (min-width: 768px) {
	}
}

.multi-rows-swiper {
	&.swiper-container {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		.swiper-wrapper {
			position: relative;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			transition-property: transform;
			box-sizing: content-box;
		}
	}
	.swiper-slide {
		height: calc((100% - 30px) / 2);
		text-align: center;
		font-size: 18px;
		background: #fff;
		height: calc((100% - 30px) / 2);
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}
}

.roundedControls {
	.swiper-container {
		.swiper-button-next,
		.swiper-button-prev {
			position: absolute;
			top: 50%;
			height: 80px;
			width: 80px;
			margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--swiper-navigation-color, var(--swiper-theme-color));
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			@media only screen and (max-width: 768px) {
				display: none;
			}
			svg {
				width: 80px;
				border-radius: 80px;
				height: auto;
				path {
					fill: black;
				}
				z-index: 10;
			}
			&:after {
				content: none;
			}
		}
		.swiper-button-next {
			right: 2.77%;
		}
		.swiper-button-prev {
			left: 2.77%;
			transform: rotate(180deg);
		}

		.swiper-button-next--bottom,
		.swiper-button-prev--bottom {
			top: unset;
			bottom: -80px;
		}

		.swiper-button-next--bottom {
			right: 38%;
			@media only screen and (min-width: 1200px) {
				right: 42%;
			}
		}
		.swiper-button-prev--bottom {
			left: 38%;
			transform: rotate(180deg);
			@media only screen and (min-width: 1200px) {
				left: 42%;
			}
		}
	}
}

.vertical-swiper {
	.swiper-pagination.swiper-pagination-inner.swiper-pagination-bullets.swiper-pagination-clickable {
		border: 1px solid red;
		position: absolute;
		left: unset;
		right: -22%;
		top: 50%;
		bottom: unset;
		transform: translate(0%, 50%) rotate(90deg);
	}
}

@media screen and (min-width: 768px) {
	.hiddenDsk {
		display: none !important;
	}
}

@media screen and (max-width: 768px) {
	.hiddenMbl {
		display: none !important;
	}

	.swiper-step-on-mobile {
		.swiper-wrapper-outer {
			padding-top: 20px;
			margin-bottom: 20px;
		}
		.swiper-slide-outer {
			transition: margin 0.5s ease-in-out;
			// transform: translateY(0px);
			margin-top: 20px;
			&.swiper-slide-active {
				// transform: translateY(-20px);
				margin-top: -20px;
				margin-bottom: 40px;
			}
			.inner-wrapper .image-wrapper {
				width: 100%;
				height: 213px;
			}
		}
	}
}

// .learn-more__hover{
//   &.swiper-slide{
//     .inner-wrapper .image-wrapper .learn-more{
//       transition: width 0.5s ease-in-out;
//     }
//     &:hover{
//       .inner-wrapper .image-wrapper .learn-more{
//         width: 100%;
//       }
//     }
//   }
// }

.default-padding-container {
	padding-left: 6.11%;
	padding-right: 6.11%;
}

.learn-more__hover {
	.image-wrapper .learn-more {
		width: 0%;
		padding: 15px 30px 15px 15px;
		.inner-text {
			opacity: 0;
		}
		svg path {
			fill: black;
		}
	}

	&:hover {
		.image-wrapper .learn-more {
			width: 90%;
			padding: 15px 30px 15px 15px;
			.inner-text {
				opacity: 1;
			}
			svg path {
				fill: #00000033;
			}
		}
	}
	@media screen and (min-width: 768px) {
		&.swiper-slide-active:hover .learn-more {
			bottom: -2% !important;
			right: -6% !important;
		}
	}

	@media screen and (min-width: 1360px) and (min-height: 600px) {
		&:hover {
			.image-wrapper .learn-more {
				width: 90%;
			}
		}
	}
}

section.product-models.invertedRow .inner-wrapper {
	flex-direction: row-reverse !important;
	.swiper-slide__column-img .image-wrapper .swiper-container-inner.verticalSwiper .swiper-pagination.swiper-pagination-inner.swiper-pagination-bullets.swiper-pagination-clickable {
		left: -9% !important;
		right: unset !important;
	}
}
@media screen and (max-width: 768px) {
	section.product-models.invertedRow .inner-wrapper {
		flex-direction: column !important;
	}
}

@media screen and (min-width: 768px) {
	section.product-feature .product-feature__swiper .product-feature__swiper--swiper-wrapper .swiper-slide .inner-wrapper.gallery-row-reverse {
		flex-direction: row-reverse !important;
		.swiper-slide__column__left {
			padding-left: 10px;
			padding-right: 0px;
		}
		.swiper-slide__column__right {
			padding-right: 10px;
			padding-left: 0px;
			.swiper-slide__column__top-right {
				margin-left: auto;
			}
		}
	}
}

.speed-measure {
	font-size: 20px;
	text-transform: uppercase;
}

.article {
	@media only screen and (min-width: 768px) {
		margin-bottom: 20px;
	}
	.articles-inner-wrapper {
		background-color: #252525;
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 10px;
		height: 100%;
		.category {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: 1.5px;
			text-align: left;
			color: #1f1f1f;

			margin-bottom: 10px;
		}
		.title {
			font-size: 20px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.8;
			letter-spacing: 1.5px;
			text-align: left;
			color: #1f1f1f;

			margin-bottom: 20px;
		}
		.image-wrapper {
			position: relative;
			.learn-more {
				cursor: pointer;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 2px;
				text-align: center;

				position: absolute;
				bottom: 0px;
				right: 0px;
				background-color: #252525;
				text-transform: uppercase;
				white-space: nowrap;
				overflow: hidden;
				transition: all 0.5s ease-in-out;
				.inner-text {
					color: white;
					text-decoration: none;
					width: 100%;
					transition: opacity 0.2s ease-in-out;
				}
				.svg-wrapper {
					background-color: white;
					height: 100%;
					width: 45px;
					position: absolute;
					right: 0px;
					top: 0px;
					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						max-width: 20px;
						transition: fill 0.5s ease-in-out;
						path {
							fill: white;
						}
					}
				}
			}
		}
	}
	.text-wrapper {
		background-color: #252525;
		padding: 20px 20px 25px 20px;
		display: flex;
		flex-direction: column;
		position: relative;
		flex-grow: 1;
		.article-title {
			margin-top: 21px;
			text-transform: uppercase;
			text-align: left;
			span {
				font-size: 20px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.1;
				letter-spacing: 1px;
				color: #1f1f1f;
			}
		}
		.article-preview {
			height: 102px;
			margin-top: 16px;
			text-align: left;

			span {
				font-size: 18px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.56;
				letter-spacing: normal;
				color: #1f1f1f;
			}
		}
		.continue-reading {
			margin-top: 5px;
			display: inline-flex;
			justify-content: flex-end;
			flex-direction: row;
			padding-right: 60px;
			position: relative;
			cursor: pointer;
			margin-top: auto;
			.long-arrow {
				svg {
					height: 26px;
					max-width: 50px;
					position: absolute;
					right: 0px;
					top: 0px;
					transform: translateY(-6px);
					path {
						fill: #00000033;
					}
				}
			}
			span {
				font-size: 16px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 2px;
				text-align: center;
				color: #1f1f1f;

				text-transform: uppercase;
			}
		}
	}
}

.margin-auto {
	display: flex;
	margin: auto;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.width-100 {
	width: 100%;
}

.circle-bullets.swiper-container {
	.swiper-pagination.swiper-pagination-bullets.swiper-pagination-clickable {
		display: block;
		position: absolute;
		text-align: center;
		transition: 0.3s opacity;
		transform: translate3d(0, 0, 0);
		z-index: 10;
		@media screen and (max-width: 768px) {
			display: none;
		}
		.swiper-pagination-bullet {
			width: 4px;
			height: 4px;
			margin: 9px 12px;
			opacity: 0.3;
			background-color: #ffffff;
			-webkit-transition: 0.3s opacity ease-in-out, 0.3s height ease-in-out, 0.3s width ease-in-out;
			transition: 0.3s opacity ease-in-out, 0.3s height ease-in-out, 0.3s width ease-in-out;
			&:after {
				content: "";
				width: 22px;
				height: 22px;
				position: absolute;
				-webkit-transform: translate(-50%, -36%);
				transform: translate(-50%, -36%);
				border-radius: 50px;
				border: 1px solid white;
				opacity: 0;
				-webkit-transition: 0.3s opacity ease-in-out;
				transition: 0.3s opacity ease-in-out;
			}
			&-active {
				width: 6px;
				height: 6px;
				background-color: #ffffff;
				opacity: 1;
				box-shadow: 0 0 14px 0 #000;
				&:after {
					opacity: 1;
				}
			}
		}
	}
}

.side-top-view-wrapper {
	.side-top-view {
		position: relative;
		.sideview-image {
			position: relative;
			z-index: 10;
			transition: 0.4s opacity ease-in-out;
		}
		&:hover {
			.topview-image {
				z-index: 20;
				opacity: 1;
			}
			.sideview-image {
				opacity: 0;
			}
		}
	}
}

.topview-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	opacity: 0;
	transition: 0.4s opacity ease-in-out;
}

.positionFixed {
	position: fixed;
}

.color-edition-DynamicLabel-container {
	position: relative;
	display: table;
	.editionDetails {
		display: table;
	}

	.color-edition-dynamicLabel {
		display: table;
		position: absolute;
		top: -2px;
		left: 147px;
		width: 200px;
		opacity: 0;
		transition: 0.3s opacity ease-in-out;
		&.active {
			opacity: 1;
		}
	}
	@media screen and (max-width: 1440px) {
		.color-edition-dynamicLabel {
		}
	}
}
@media screen and (max-width: 414px) {
	.color-edition-DynamicLabel-container .color-edition-dynamicLabel {
		left: 129px;
	}
}

.learn-more-slide .color-edition-DynamicLabel-container .color-edition-dynamicLabel {
	left: 142px;
	width: 190px;
	@media screen and (min-width: 768px) {
		left: 144px;
		width: 191px;
	}
	@media screen and (max-width: 414px) {
		left: 128px;
		width: 190px;
		top: 1px;
	}
}

#sticky_cta.elevated {
	@media screen and (max-width: 768px) {
		padding-bottom: 20px;
		height: 65px;
	}
}

.hiddenSmallMbl {
	@media screen and (max-width: 413px) {
		display: none !important;
	}
}

.slider-label__archive {
	background: #1f1f1f;
	padding: 4px;
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	position: absolute;
	right: 0;
	top: 20px;
}
