.p-modal-trigger {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.5);
  z-index: 99;
}

.p-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background: $white;
  width: 500px;
  display: none;
  z-index: 100;

  &__header {
    margin-bottom: 30px;
  }

  &__footer {
    margin-top: 30px;
  }

  &-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: $black;
    cursor: pointer;
  }

  .player-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &[modal-type="video"] {
    padding: 0;

    .p-modal-close {
      width: 30px;
      height: 30px;
      background: transparent;
      color: $white;
      text-align: right;
      top: -35px;
    }

  }

}
