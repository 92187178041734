$yellow: #ffdd00;
$red: #d2373a;
$black: #000000;
$grey: #333333;
$lightGrey: #eeeeee;
$mediumGray: #7A7A7A;
$white: #ffffff;

$error: #da3832;
$success: #5ba938;
