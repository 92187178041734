//Black version
html {
	background-color: #1f1f1f;

	body {
		background-color: #1f1f1f;
	}

	section.product-feature {
		max-width: 1440px;
		margin: 0 auto;
		overflow: hidden;
	}

	section.product-feature .product-feature__swiper .product-feature__swiper--swiper-wrapper .swiper-slide .inner-wrapper .swiper-slider_cental-block {
		background-color: #1f1f1f;
	}

	body,
	body.darkversion#darkversion {
		section,
		section.product-hero .hero-copy,
		section.product-hero .hero-text,
		section.gallery-section .gallery-copy,
		section.gallery-section .gallery-title,
		section.product-feature .product-feature__swiper .product-feature__swiper--swiper-wrapper .swiper-slide .inner-wrapper .swiper-slide__column__top-left,
		section.product-feature .product-feature__swiper .product-feature__swiper--swiper-wrapper .swiper-slide .inner-wrapper .swiper-slider_cental-block,
		section.product-feature .product-feature__swiper .product-feature__swiper--swiper-wrapper .swiper-slide .inner-wrapper .swiper-slider_cental-block .central-bold,
		section.visit-website-section h2.internal__title,
		.intro .container .intro-text-copy,
		.swiper-slide .inner-wrapper .title,
		.section-title,
		.swiper-slide .inner-wrapper .category {
			color: white;
		}

		section.product-hero .hero-copy {
			a,
			a:visited,
			a:active,
			a:hover {
				color: white;
				font-weight: bold;
				text-decoration: underline;
			}
		}

		section#product-hero .ghostText {
			color: white;
		}

		#product-feature.roundedControls .swiper-container .swiper-button-next svg,
		#product-feature.roundedControls .swiper-container .swiper-button-prev svg {
			background: #1f1f1f;
		}

		#product-models,
		#product-models-2 {
			&.roundedControls .swiper-container .swiper-button-next svg,
			&.roundedControls .swiper-container .swiper-button-prev svg {
				background: #252525;
			}
		}

		.roundedControls .swiper-container .swiper-button-next:after,
		.roundedControls .swiper-container .swiper-button-prev:after {
			border: 1px solid white;
		}

		.roundedControls .swiper-container .swiper-button-next svg path,
		.roundedControls .swiper-container .swiper-button-prev svg path {
			fill: white;
		}

		section#product-models.product-models .swiper-slide__cta .btn-plus,
		.btn-plus {
			color: black;
			background-color: white;
			cursor: pointer;
		}

		section#product-models-anniversary,
		section#product-models,
		section#product-models-2 {
			&.product-models {
				background-color: #252525;

				.swiper-wrapper .swiper-slide {
					background-color: inherit;

					.swiper-slide__title {
						color: white !important;
					}

					.swiper-slide__copy {
						color: white !important;
					}

					.swiper-slide__cta a {
						color: black;
						background-color: white !important;
					}
				}

				.swiper--tabs-wrapper .tab-separator,
				.swiper--tabs-wrapper .tab {
					color: white !important;
				}

				.swiper-container .swiper-button-next,
				.swiper-container .swiper-button-prev {
					svg path {
						fill: #ffffff !important;
					}

					&:after {
						background-color: #252525 !important;
						border: solid 1px #ffffff !important;
					}
				}
			}
		}

		#product-feature-swiper.swiper-container,
		.swiper-container {
			.swiper-pagination-outer,
			.swiper-pagination:not(.learnmore-pagination) {
				.swiper-pagination-bullet-active {
					box-shadow: 0 0 14px 0 rgba(0, 0, 0, 1);
				}

				@media screen and (max-width: 768px) {
					.swiper-pagination-bullet {
						opacity: 0.3;
						background-color: #ffffff;
					}

					.swiper-pagination-bullet-active {
						background-color: #ffffff !important;
						opacity: 1 !important;
						box-shadow: none;
					}
				}
			}

			.learnmore-pagination {
				@media screen and (max-width: 768px) {
					padding: 0px 36px;

					.swiper-pagination-bullet {
						margin-right: 36px;
						margin-top: 36px;

						&:last-of-type {
							margin-right: 0px;
						}
					}
				}
			}
		}

		.swiper-slide .inner-wrapper .image-wrapper .learn-more .svg-wrapper {
			background-color: #1f1f1f;
		}

		.pre-footer,
		footer#footer {
			background-color: black;
		}

		.swiper-slide .inner-wrapper .image-wrapper .learn-more {
			background-color: #1f1f1f;
		}

		.related-article .related-article-body .article .text-wrapper {
			background-color: #252525;

			.category-tag,
			.article-title,
			.article-preview {
				span {
					color: white;
				}
			}

			.related-article-body .article .text-wrapper .continue-reading span {
				background-color: #1f1f1f;
			}
		}

		.btn-go-to-website {
			border: 1px solid #d3d3d3;
			background-color: white;

			a {
				color: black;
			}
		}

		.related-article .related-article-body .article .text-wrapper .continue-reading {
			.long-arrow svg path {
				fill: white;
			}

			span {
				color: white;
			}
		}

		// section.product-hero .container .column-right .image-wrapper {
		//   filter: brightness(0.6);
		// }
		@media screen and (min-width: 768px) {
			.image-shadow {
				box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.6);
			}
		}

		@media screen and (min-width: 768px) {
			.image-shadow {
				box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.6);
			}
		}

		@media screen and (min-width: 1441px) {
			.image-shadow {
				width: fit-content;
				video {
					width: 100%;
				}
			}
		}

		@media screen and (max-width: 767px) {
			section#product-models-anniversary.product-models,
			section#product-models.product-models,
			section#product-models-2.product-models {
				background-color: #1f1f1f;
			}

			section.product-feature .product-feature__swiper .product-feature__swiper--swiper-wrapper .swiper-slide .inner-wrapper .swiper-slider_cental-block {
				background-color: transparent;
			}

			section.product-models.twoColumnLayout .inner-wrapper .swiper-slide__column-img .image-wrapper {
				height: unset;
			}

			section#product-models-anniversary.product-models .swiper-wrapper .swiper-slide-outer,
			section#product-models.product-models .swiper-wrapper .swiper-slide-outer,
			section#product-models-2.product-models .swiper-wrapper .swiper-slide-outer {
				background-color: #272727;
				text-align: center;
			}
		}
	}
}

html body section#product-models.product-models,
html body section#product-models-anniversary.product-models,
html body section#product-models-2.product-models,
html body.darkversion#darkversion section#product-models.product-models,
html body.darkversion#darkversion section#product-models-2.product-models {
	.swiper-container-outer {
		max-width: 1440px;
		margin: 0 auto;
	}
}
