.responsive-table {
  tr {
    border-top: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;

    &:nth-child(even) {
      background: $lightGrey !important;
    }
  }
  th {
    display: none;
    font-weight: bold;
  }

  td {
    display: block;
    font-size: 12px;
    padding: 15px;

    &:before {
      display: none;
      @media screen and (max-width: 640px) {
        content: attr(data-th)": ";
        width: 100%;
        display: inline-block;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }

  th, td {
    text-align: left;

    @media (min-width: 640px) {
      display: table-cell;
      padding: 10px 15px;
    }
  }
}