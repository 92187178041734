@import "vendors/normalize";
@import "vendors/reset";

@import "config/colors";
@import "config/fonts";

@import "modules/buttons";
@import "modules/chechbox";
@import "modules/form";
@import "modules/table";
@import "modules/modal";
@import "modules/accordion";

@import "config/helper";
@import "style-v2";
@import "topnav";
@import "footer";
@import "utils";
@import 'black__theme';

html {
  overflow-x: hidden;

  body {
    font-family: $gotham;
    // background: #fff;
    margin: 0;
    overflow: initial;

    &.hidden {
      overflow: hidden;
			height: 100vh;
    }

    .intro {
      margin-top: 45px;
      .container {
        .intro-text {
          &-copy {
            font-size: 1.125rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: normal;
            text-align: center;
            color: #1f1f1f;

            margin-top: 24px;
            @media screen and (max-width: 768px) {
              font-size: 1rem;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}
.not-found__content{
  display: flex;
  align-items: baseline;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  color: white;
  h1{
    margin-top: auto;
    width: 100%;
    font-size: 80px;
  }
  p{
    width: 100%;
    margin-top: 30px;
  }
  .row.ta-c{
    width: 100%;
    margin-bottom: auto;
    .btn.btn-filled.btn-filled--yellow.brs-4.dis-ib{
      margin-top: 50px;

    }
    span{
      display: block;
      margin-top: 30px;

    }
  }
}

sup {
  position: relative;
  top: -5px;
}

small {
  font-size: 60%;
}

main {
	overflow: hidden;
}

* {
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

.fa {
  font-family: $fontAwesome;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.3);
  z-index: 10;
}

section,
.row {
  width: 100%;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 6.25%;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .intro .container .intro-text-copy {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1200px) {
  section{
    min-height: 550px;
    &#product-models{
      padding-bottom: 52px;
    }
  }
}
@media screen and (min-width: 1441px) {
  .container {
    max-width: 1440px;
    padding: 0 6.25%;
  }
}

.custom-container{
  padding-left: 6.11%;
  padding-right: 6.11%; 
}

// @media screen and (max-width: 768px) {
//   body{
//     padding-bottom: 45px;
//   }
// }

.mobileSticky__wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  height: 45px;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  z-index: 9999;
  span{
    margin: auto;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2px;
    text-align: center;
    color: #1f1f1f;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #related-article .custom-container{
    padding-left: 0px;
    padding-right: 0px;
    .articles-inner-wrapper{
      padding: 0px;
    }
  }
}

@media only screen and (min-width: 1201px){
  .main-content:not(.tecnorib) {
    #product-models-2,#product-models{
      height: 105vh;
      min-height: 610px; //pirelli 2 res
      max-height: 1080px;
    }
  }
}

@media only screen and (min-width: 1366px){
  .main-content:not(.tecnorib) {
    #product-models-2,#product-models{
      height: 100vh;
      min-height: 610px; //pirelli 2 res
      max-height: 1080px;
    }
  }
}

