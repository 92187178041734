form {
  font-family: $gotham;

  input {
    float: left;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: 16px;
    line-height: 50px;
    padding: 0 15px;
    border-bottom: 2px solid transparent;
    -webkit-appearance: none;
    font-family: inherit;

    &::placeholder {
      font-size: 90%;
      opacity: 0.7;
    }

    &[type="submit"] {
      float: left !important;
      padding: 0 25px !important;
      font-family: 'FontAwesome', 'Gotham';
      font-size: 12px !important;
      background: #ffdd00 !important;
      text-transform: uppercase;
      -webkit-border-radius: 4px !important;
      -moz-border-radius: 4px !important;
      border-radius: 4px !important;
      font-weight: 500 !important;
      color: #000 !important;
      text-align: left;
      margin-top: 30px;
      border: 0;
      width: auto !important;
      cursor: pointer;
    }

  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  /*

  .select {
    float: left;
    width: 100%;
    position: relative;
    &:after {
      content:"\f107";
      font-family: FontAwesome;
      line-height: 50px;
      position: absolute; right: 15px; top: 0;
      z-index: 1;
      text-align: center;
      width: auto;
      height: 100%;
      pointer-events: none;
      cursor: pointer;
    }
  }


  select {
    float: left;
    width: 100%;
    height: 50px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;
    border-bottom: 2px solid transparent;
    padding: 0 15px;
    line-height: 50px;
    font-size: 16px;
    font-family: inherit;
  }

  option {
    font-size: 30px;
  }
*/

  &[data-theme="light"] {
    input {
      background: $white;
      border: 1px solid $lightGrey;
      color: $grey;

      &::placeholder {
        opacity: 1;
      }

      &.error {
        border-top: 1px solid $lightGrey;
        border-left: 1px solid $lightGrey;
        border-right: 1px solid $lightGrey;
        border-bottom: 2px solid $error;
      }
      &.valid {
        border-top: 1px solid $lightGrey;
        border-left: 1px solid $lightGrey;
        border-right: 1px solid $lightGrey;
        border-bottom: 2px solid $success;
      }
    }
    /*.select {
      &:after {
        color: $black;
      }
    }
    select {
      background: $white;
      border: 1px solid $lightGrey;
      color: $grey;
    }*/
  }

  &[data-theme="dark"] {
    input {
      background: $black;
      color: $white;
      border:0;
      border-bottom: 4px solid transparent;

      &.error {
        border-bottom: 4px solid $error;
      }
      &.valid {
        border-bottom: 4px solid $success;
      }
    }
    /*.select {
      &:after {
        color: $white;
      }
    }
    select {
      background: $black;
      color: $white;
    }*/
  }


}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  //border: 1px solid $lightGrey;
  padding: 0 15px;
  line-height: 50px;
  font-size: 16px;
  width: 100%;

  &.error {
    & + .select__styled {
      border-bottom: 4px solid $error;
    }
  }
  &.valid {
    & + .select__styled {
      border-bottom: 4px solid $success;
    }
  }

  &__styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 15px;
    transition: all 0.2s ease-in;
    font-size: 16px;
    color: $grey;
    border-bottom: 4px solid transparent;

    &:after {
      content:"\f107";
      font-family: FontAwesome;
      line-height: 50px;
      position: absolute; right: 15px; top: 0;
      z-index: 1;
      text-align: center;
      width: auto;
      height: 100%;
      pointer-events: none;
      cursor: pointer;
    }

    &:active,
    .active {
      &:after {
        content:"\f107";
        font-family: FontAwesome;
        line-height: 50px;
        position: absolute; right: 15px; top: 0;
        z-index: 1;
        text-align: center;
        width: auto;
        height: 100%;
        pointer-events: none;
        cursor: pointer;
       }
    }
  }

  &__options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    border: 0;

    li {
      margin: 0;
      padding: 0 15px;
      transition: all 0.15s ease-in;
      border-top: 1px solid #212121;
      &:last-of-type {
        border-bottom: 0;
      }
      &:hover {
        background: $lightGrey;
      }
      &[rel="hide"] {
        display: none;
      }
    }
  }

  &[data-theme="dark"] {
    & + .select__styled {
      background-color: $black;
      color: $white;

      & + .select__options {
        background: $black;
        color: $lightGrey;

        li {
          &:hover {
            background: $black;
          }
        }
      }
    }
  }

  &[data-theme="light"] {
    & + .select__styled {
      background-color: $white;

      & + .select__options {
        background: $white;
        color: $grey;
      }
    }
  }
}


