@import "common";
@import "./modules/modal-video";

html {
	body {
		main.main-content#hp {
			padding-bottom: 40px;
		}

		.hero {
			width: 100%;
			position: relative;
			z-index: 100;
			height: 100vh;

			@media screen and (max-width: 768px) {
				height: fit-content;
			}

			background: none;
			width: 100%;
			position: relative;

			&:after {
				content: none;
				// content:'';
				position: absolute;
				top: 0px;
				bottom: 0;
				left: 0px;
				height: 100%;
				right: 0px;
				// background-color: rgba(0, 0, 0, 0.17);
				// background-color: red;
				z-index: 100;
			}

			.hero__video {
				width: 100%;
				position: relative;

				//@media screen and (min-width: 767px) {
				//  width: auto;
				//  height: 100vh;
				//}
				&.hiddenMbl {
					@media screen and (min-width: 768px) {
						display: block !important;
					}
				}
			}

			&_background {
				height: 100vh;
				// height: 100%;
				width: 100%;
				background-size: cover;
				background-position: center;
				overflow: hidden;
				text-align: center;

				picture {
					img {
						cursor: pointer;
						width: 100vw;
					}
				}
			}

			&_text {
				text-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
				font-size: 100px;
				font-weight: 100;
				font-stretch: normal;
				// font-style: italic;
				line-height: 0.9;
				letter-spacing: 1px;
				text-align: center;
				color: #ffffff;

				z-index: 200;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 60%;
				transform: translate(-50%, -50%);
			}
		}

		.insta-feed {
			text-align: center;
			margin-top: 98px;

			.container {
				padding: 0px;
			}

			.blueButton {
				margin-top: 30px;
			}

			.title {
				font-size: 2.75rem;
				font-weight: normal;
				font-stretch: normal;
				// font-style: italic;  //test comment
				line-height: 1.14;
				letter-spacing: normal;
				text-align: center;
				color: #1f1f1f;

				margin-bottom: 39px;
			}

			.insta-feed-body {
				max-width: 1580px;
				//    height: 700px;
				margin: 0 auto;
				padding: 0 10px;

				display: flex;
				flex-wrap: wrap;

				.imgWrapper {
					width: calc(50% - 20px);
					margin: 10px;

					@media only screen and (min-width: 768px) {
						width: calc(33.333333% - 20px);
					}

					@media only screen and (min-width: 992px) {
						width: calc(25% - 20px);
					}

					@media only screen and (min-width: 1200px) {
						width: calc(20% - 20px);

						&:nth-child(12),
						&:nth-child(11) {
							display: none;
						}
					}
				}

				img {
					max-width: 100%;
					height: auto;
					display: block;
				}

				.swiper-slide {
					position: relative;
					background-color: transparent;

					&:after {
						content: "";
						display: block;
						padding-bottom: 100%;
					}

					img {
						max-width: 100%;
						display: block;
					}

					.inner-wrapper {
						background-color: #8080801f;

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 50%;
							height: auto;
							transform: translate(-50%, -50%);

							path {
								fill: #5959595c;
							}
						}
					}
				}
			}
		}

		.related-article {
			margin-top: 70px;

			.swiper-wrapper {
				margin-bottom: 50px;
			}

			.swiper-pagination {
				@media screen and (max-width: 768px) {
					display: none;
				}
			}

			.related-article-body {
				margin-top: 40px;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
		}

		@media only screen and (max-width: 768px) {
			.container-max {
				text-align: center;

				.insta-feed a.title {
					font-size: 2rem;
					margin-bottom: 40px;
				}
			}

			/*.hero .hero__video {
        width: 100%;
        height: auto;
      }*/
			.hero_text {
				position: absolute;
				width: 95%;
				bottom: 50%;
				top: unset;
				font-size: 2.5rem;
				transform: translate(-50%, 50%);
			}

			.insta-feed .insta-feed-body {
				//  height: 600px;
				.multi-rows-swiper .swiper-slide {
					//          height: calc((100% - 40px) / 5);
					text-align: center;

					.inner-wrapper {
						width: 100%;
						height: 100%;
						padding: 0px;

						.image-wrapper {
							width: 100%;
							height: 100%;
						}

						.category {
							font-size: 9px;
						}

						.title {
							font-size: 16px;
						}
					}
				}
			}

			.related-article {
				.container {
					padding: 0px;
				}

				.related-article-body {
					.article {
						.inner-wrapper {
							padding: 0px;
						}

						.text-wrapper {
							.article-title span {
								font-size: 1rem;
							}

							.article-preview span {
								font-size: 1rem;
							}

							.continue-reading {
								span {
									display: none;
								}

								.long-arrow {
									svg {
										path {
											fill: black;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.articles-page-link {
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 2px;
	text-align: right;
	color: #ffffff;
	height: 150px;
	text-align: right;
	display: block;
	padding: 0 6.11%;
	text-transform: uppercase;

	a {
		color: white;
		margin-bottom: 39px;
	}

	.long-arrow {
		display: inline;
	}

	svg {
		height: 26px;
		margin-bottom: -7px;
		padding-left: 26px;
	}
}

@media only screen and (min-width: 768px) {
	html body .related-article .related-article-body .swiper-slide {
		height: auto;
	}

	.article .text-wrapper .article-title {
		height: 44px;
	}

	.article .text-wrapper .article-preview {
		height: 102px;
	}
}

@media only screen and (max-width: 768px) {
	html body .insta-feed a.title {
		font-size: 2rem;
		margin-bottom: 40px;
	}

	.iframeContainer.hiddenDsk {
		margin-top: 40px;
		padding: 0px 20px;
	}
}

.iframeContainer.hiddenMbl {
	padding: 10px;
}

.hero-cta__btn {
	a {
		text-transform: uppercase;
		font-size: 1rem;
		font-weight: bold;
		font-style: normal;
		letter-spacing: 2px;
		text-align: center;
		color: black;
		background-color: white !important;
		padding: 20px 40px;
		border-radius: 120px;
		cursor: pointer;
		justify-content: center;
		margin-right: 20px;
		display: inline-block;
	}
}
