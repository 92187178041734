@import "vendors/normalize";
@import "vendors/reset";

@import "config/colors";
@import "config/fonts";

.pre-footer {
	width: 100%;
	height: 80px;
	line-height: 80px;
	text-align: center;
	background: #232325;
	margin-bottom: -1px;
	@media only screen and (max-width: 768px) {
		margin-top: 30px;
	}

	.container {
		position: relative;
		z-index: 2;
	}

	.logo {
		position: relative;
		height: 20px;

		img {
			width: 100%;
			max-width: 150px;
		}

		svg {
			width: 297px;
			height: 18px;
			path {
				fill: $white;
			}
		}
	}
}

footer#footer {
	width: 100%;
	background: #232325;
	position: relative;

	@media screen and (max-width: 1100px) {
	}

	.footer {
		&-content {
			@media screen and (max-width: 1100px) {
				text-align: center;
			}
			.first-row {
				//height: 80px;
				padding-top: 30px;
				//padding-bottom: 30px;
				border-bottom: 1px solid #ffffff4d;
				border-top: 1px solid #ffffff4d;
				display: flex;
				flex-direction: row;
			}
			.second-row {
				padding-top: 30px;
				padding-bottom: 54px;
			}
			.logo {
				position: relative;
				height: 20px;
				.svg-in {
					max-width: 150px;
					fill: $white;
					height: 20px;
				}
				&:after {
					content: "";
					position: absolute;
					left: 50%;
					top: calc(100% + 10px);
					transform: translateX(-50%);
					width: 35px;
					height: 2px;
					background: $yellow;
				}
			}

			.trademarks {
				display: inline-flex;
				width: 100%;
				justify-content: space-between;

				@media screen and (max-width: 1100px) {
					max-width: 100%;
					width: 100%;
					padding-bottom: 30px;
					text-align: center;
				}

				p {
					display: inline-block;
					font-size: 11px;
					line-height: 16px;
					font-weight: 400;
					color: #fff;
					margin: auto;
					margin-left: 30px;
				}
				img {
					max-width: 330px;
				}
			}

			nav.footer-nav {
				float: left;
				width: auto;
				margin-bottom: 30px;

				@media screen and (max-width: 1100px) {
					width: 100%;
					text-align: center;
				}

				ul {
					float: left;
					width: 100%;
					text-align: right;
					@media screen and (max-width: 1100px) {
						text-align: center;
						line-height: 1.5;
					}

					li {
						text-transform: uppercase;
						font-size: 10px;
						letter-spacing: 0.5px;
						color: $white;
						display: inline-block;
						vertical-align: baseline;
						margin: 0 15px;

						&:last-child {
							margin-right: 0;
						}

						a {
							font-size: 0.875rem;
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: normal;
							letter-spacing: 1px;
							text-align: left;
							color: #ffffff;
						}
					}
				}
			}

			.social {
				position: relative;
				padding: 0 15px;
				display: inline-block;
				li {
					display: inline-block;
					color: $white;
					vertical-align: middle;
					margin: 0 8px;
					font-size: 22px;
					a {
						color: inherit;
					}
				}
				@media screen and (max-width: 1100px) {
					display: flex;
				}
			}
			.contact-us,
			.copy {
				float: right;
				margin-top: 6px;
				color: $white;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 2px;
				text-align: left;
			}
			.social-wrapper {
				margin-left: auto;
			}
			.follow-us-label {
				font-size: 0.875rem;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 21px;
				letter-spacing: 1px;
				text-align: left;
				color: #ffffff;
				text-transform: uppercase;
				width: auto;
				display: inline-block;
				.separator {
					width: 40px;
					display: inline-block;
					border-bottom: 1px solid #ffffff4d;
					margin-bottom: 5px;
					margin-left: 5px;
				}
			}

			.contact-us {
				position: relative;
				&:after {
					content: "";
					position: absolute;
					left: 0;
					top: 100%;
					margin-top: 2px;
					width: 100%;
					height: 1px;
					background: $white;
				}
			}
		}
	}
}

@media only screen and (max-width: 1201px) {
	footer#footer .footer-content .follow-us-label {
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	.pre-footer .logo .svg-in {
		width: 200px;
		height: auto;
	}
	footer#footer .footer-content {
		padding: 0px 20px;
	}
	footer#footer .footer-content .first-row {
		flex-direction: column-reverse;
		height: auto;
		padding-bottom: 20px;
	}
	footer#footer .footer-content nav.footer-nav ul li {
		margin: 10px 4px;
		a {
			font-size: 12px;
		}
	}
	footer#footer .footer-content nav.footer-nav {
		margin: 15px 0px 0px 0px;
	}
	footer#footer .footer-content .trademarks {
		display: flex;
		flex-direction: column;
		img,
		p,
		.copy {
			margin-left: auto;
			margin-right: auto;
		}
		p {
			margin-top: 10px;
		}
	}
	footer#footer .footer-content .copy {
		margin-top: 40px;
		float: none;
	}
	footer#footer .footer-content .second-row {
		padding-bottom: 0px;
	}

	footer#footer .footer-content .social-wrapper {
		margin-left: unset;
		display: flex;
		flex-direction: row;
		width: fit-content;
		margin: auto;
	}
	footer#footer .footer-content .follow-us-label {
		display: block;
		margin-right: 12px;
		font-size: 12px;
		.separator {
			display: none;
		}
	}
	footer#footer .footer-content ul.social {
		width: auto;
		margin: 0px;
		li {
			margin: 0px 17px;
			width: auto;
			&:first-of-type {
				margin-left: 0px;
			}
			&:last-of-type {
				margin-right: 0px;
			}
		}
	}
}

@media only screen and (max-width: 320px) {
	footer#footer {
		.footer-content {
			.follow-us-label {
				font-size: 0.584rem;
				.separator {
					display: none;
				}
			}
			.trademarks img {
				max-width: 250px;
			}
		}
	}
}
