.accordion {
  float: left;
  width: 100%;
  text-align: left;
  position: relative;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &-wrapper {
    float: left;
    width: 100%;
  }

  &__toggle {
    float: left;
    width: 100%;
    position: relative;
    padding: 5px 0px;
    border-bottom: 1px solid $black;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      box-shadow: 2px -2px 0 0 $black inset;
      padding: 6px;
      bottom: 10px;
      right: 10px;
      transition: all .3s ease;
      transform: rotate(-45deg);
      cursor: pointer;
    }

    &.open {
      transition: all 0.3s ease;
      margin-bottom: 0;
      &:after {
        bottom: 4px;
        transform: rotate(135deg);
      }
    }
  }
  &__content {
    float: left;
    width: 100%;
    display: none;
    //padding: 15px 0;
    border-top: 1px solid #f2f2f2;
    margin-bottom: 30px;

    a {
      color: inherit;
    }

  }
}

